<template>
  <div class="try">
    <div class="try_title">
      <h1>申请试用</h1>
      <span class="line"></span>
    </div>
    <div class="try_input">
      <input class="name_input" placeholder="你的名字" v-model="from.name" />
      <input class="tel_input" placeholder="手机号码" v-model="from.phone" />
      <button class="btn" @click="submit">申请试用</button>
    </div>
    <p>幻想挑战科技，科技启发幻想</p>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      from: {
        name: "",
        phone: "",
      },
    };
  },
  methods: {
    // 申请试用
    submit() {
      if (this.from.name.length == 0 || this.from.phone.length == 0) {
        if (this.from.name.length == 0) {
          this.$message({
            message: "请输入姓名",
            type: "warning",
          });
          return;
        }
        if (this.from.phone.length == 0) {
          this.$message({
            message: "手机号不能为空",
            type: "warning",
          });
          return;
        }
      } else {
        if (this.from.phone) {
          let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
          if (!reg.test(this.from.phone)) {
            this.$message({
              message: "请输入正确手机号",
              type: "warning",
            });
          } else {
            const { name, phone, wx, qq, message, title } = this.from;
            const option = {
              // eslint-disable-next-line no-undef
              // baseURL: `${location.protocol}//${location.hostname}:${MAIL_CONFIG.serverPort}/email`
              /* url: "https://sendemail.toivan.com/email", */
              url: "https://sendemail.toivan.com/tillusoryEmail",
              method: "post",
              timeout: 2000,
              // `headers` 是即将被发送的自定义请求头
              headers: { "X-Requested-With": "XMLHttpRequest" },
              // `withCredentials` 表示跨域请求时是否需要使用凭证
              withCredentials: true, // 默认的
              // eslint-disable-next-line no-undef
              // data: MAIL_TEXT({ name, phone, wx, qq, message })
              data: { name, phone, wx, qq, message, title },
            };
            axios(option)
              .then(({ data }) => {
                // const { success, info } = data
                const { success } = data;
                if (success) {
                  this.$message({
                    offset: 100,
                    showClose: true,
                    message: "提交成功",
                    type: "success",
                  });
                } else {
                  this.$message({
                    offset: 100,
                    showClose: true,
                    message: "发送失败",
                    type: "error",
                  });
                }
              })
              .catch((e) => {
                console.log(e.message, typeof e.message, "e");
                // this.send(e.message)
                this.$message({
                  offset: 100,
                  showClose: true,
                  message: "提交失败",
                  type: "error",
                });
              });
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 申请试用
.try {
  height: 380px;
  background: url("../../assets/product/bg_shiyong@2x.png");
  background-size: 100% 380px;
  padding-top: 80px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  text-align: center;
  .try_title {
    margin: auto;
    h1 {
      height: 38px;
      font-size: 30px;
      font-weight: 500;
    }
    .line {
      display: block;
      width: 60px;
      height: 4px;
      background: #fff;
      margin: 9px auto;
    }
  }
  .try_input {
    width: 580px;
    height: 48px;
    margin: 79px auto;
    background: #ffffff;
    border-radius: 2px;
    line-height: 50px;
    color: #999999;
    .name_input {
      width: 103px;
      height: 45px;
      border-right: 1px solid #999;
      padding-left: 12px;
    }
    .tel_input {
      width: 288px;
      height: 45px;
      padding-left: 12px;
    }
    .btn {
      width: 160px;
      height: 40px;
      background: #4c8cf5;
      border-radius: 4px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
    .btn:hover {
      background: #2673f1;
      color: #ffffff;
    }
  }
}
</style>